import { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, makeStyles, Box, Checkbox } from '@material-ui/core';
import { AddCircleOutline, MoreHoriz, RemoveCircleOutline, Sync } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import { TBAAllUncontrolledNoTransSplashScreen } from './TBAAllUncontrolledNoTransSplashScreen';
import { TbaUncontrolledTransactionsHeader } from './TbaUncontrolledTransactionHeader';
import { CustomIconMenu, SearchAndSort, SortOrder, ConfirmationModal, CenteredProgress } from '../../../components';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { Container } from '../../../models';
import {
  addTBAUncontrolledTransaction,
  deassociateTBAUncontrolledTransaction,
  fetchCurrentTBA,
  fetchUncontrolledTransactionParameters,
  deleteUncontrolledTransaction,
  bulkDeleteFromAllUncontrolled,
  bulkAddToCurrentSearch,
  updateUncontrolledTransactionsChanging
} from '../../../redux/transactionBasedAnalyses';
import {
  selectCurrentUncontrolledTransactionParameters,
  selectUncontrolledTransactionsChanging,
  selectWorkingContainer
} from '../../../selectors';
import { filterData, sortData } from '../../../services/filtering';
import { AppDispatch } from '../../../store';
import tokens from '../../../styles/designTokens';
import { body2 } from '../../../styles/typography';
import { TrashCanIcon } from '../../../svgs';
import { formatRangeValue, getRangeValueHeader } from '../../../utils';
import { FinancialInfoFieldValues, financialInfoFieldTypesMap } from '../../NewAnalysisModal/CreateTBAModal.proptypes';
import { SingleChipProps } from '../../SummaryChips';
import { Table } from '../../Table';
import { StickyColsDefinition } from '../../Table/Table.proptype';
import {
  IconMenuItem,
  TbaUncontrolledParametersPayload,
  TbaUncontrolledTransactionsProps,
  UncontrolledTransaction,
  UncontrolledTransactionTableData
} from '../TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    minHeight: '14rem',
    padding: '1.375em',
    backgroundColor: theme.palette.common.white,
    overflow: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  },
  scrollTable: {
    width: '100%',
    '& .MuiTableCell-root': {
      '&.MuiTableCell-head': {
        '& .MuiIconButton-root': {
          color: tokens.product100,
          '&:hover': {
            backgroundColor: tokens.product25
          }
        }
      },
      '&.MuiTableCell-body': {
        padding: '0px 15px',
        '& .MuiIconButton-root': {
          color: tokens.product100,
          '&:hover': {
            backgroundColor: tokens.product25
          }
        }
      }
    }
  },
  rowNotSelected: {
    '&:hover': {
      '& .MuiTableCell-root': {
        backgroundColor: tokens.neutral60
      }
    }
  },
  removeAddTbaUncontrolledTransactionIcon: {
    color: tokens.neutral100,
    paddingTop: '0.375rem',
    paddingLeft: '0.375rem',
    borderRadius: '2rem',
    width: '2.25rem',
    height: '2.25rem',
    '&:hover': {
      cursor: 'pointer',
      color: tokens.product100,
      background: tokens.product25
    }
  },
  selectedTransactionId: {
    ...body2,
    color: tokens.product100,
    border: `1px solid ${tokens.product25}`,
    borderRadius: '1rem',
    textAlign: 'center',
    background: tokens.product15,
    height: '1.5rem'
  }
}));

const RotatingSyncIcon = styled(Sync)(() => ({
  animation: 'spin 2s linear infinite',
  '@keyframes spin': {
    '0%': { transform: 'rotate(360deg)' },
    '100%': { transform: 'rotate(0deg)' }
  }
}));

export const TbaUncontrolledTransShowAll = ({
  allUncontrolledTransactions,
  tba,
  tabLabel,
  isUnitPrice,
  onCreateSingleTransaction,
  onUploadMultipleTransactions,
  onCopyTba,
  onEditSingleTransaction,
  setIsOpenImportFromRoyaltyStatModal,
  savingNewTransaction
}: TbaUncontrolledTransactionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [allUncontrolledSelected, setAllUncontrolledSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingTx, setDeletingTx] = useState<UncontrolledTransaction>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [currentSearchUncontrolledTransactionIds, setCurrentSearchUncontrolledTransactionIds] = useState<number[]>([]);
  const currentUncontrolledTransactionParameters = useSelector(selectCurrentUncontrolledTransactionParameters);
  const container = useSelector(selectWorkingContainer);
  const [featureFlagIsActive] = useFeatureFlags();
  const transactionsChanging = useSelector(selectUncontrolledTransactionsChanging);
  const [tbalUncontrolledTransactions, setTbalUncontrolledTransactions] = useState<UncontrolledTransaction[]>([]);
  const [selectableAllUncontrolledTransactions, setSelectableAllUncontrolledTransactions] = useState<
    UncontrolledTransaction[]
  >([]);
  const transactionType = tba?.transactionType;
  const method = tba?.tbaEvaluationMethod.name;

  const showRangeValueFixFormatting = featureFlagIsActive('tbaRangeValuesFormatting');

  useEffect(() => {
    setSelectableAllUncontrolledTransactions(
      allUncontrolledTransactions
        ? allUncontrolledTransactions.filter((allUncontrolledTransaction) => {
            return allUncontrolledTransaction.transactionType?.name === tba.transactionType?.name;
          })
        : []
    );
  }, [allUncontrolledTransactions, tba, transactionsChanging]);
  useEffect(() => {
    setTbalUncontrolledTransactions(tba?.uncontrolledTransactions?.length ? tba?.uncontrolledTransactions : []);
  }, [tba]);
  /*
  Transaction selection is disabled until the API supports bulk actions
  const selectableAllUncontrolledTransactions = allUncontrolledTransactions
    ? allUncontrolledTransactions.filter((allUncontrolledTransaction) => {
      return allUncontrolledTransaction.transactionType?.name === tba.transactionType?.name;
    })
    : [];
    */
  useEffect(() => {
    const currentSearchUncontrolledTransactionIds = tbalUncontrolledTransactions
      ? tbalUncontrolledTransactions.map((uncontrolledTransaction) => uncontrolledTransaction.transactionId)
      : [];
    setCurrentSearchUncontrolledTransactionIds(currentSearchUncontrolledTransactionIds);
  }, [tbalUncontrolledTransactions]);

  const removeTbaUncontrolledTransactionFromCurrentSearch = async (transactionId: number, rowNumber: number) => {
    dispatch(updateUncontrolledTransactionsChanging({ type: 'ADD', rowNumber }));
    void dispatch(deassociateTBAUncontrolledTransaction({ tbaId: tba.tbaId, transactionId })).then(() => {
      setTbalUncontrolledTransactions((prev) =>
        prev.filter((tbalUncontrolledTransaction) => tbalUncontrolledTransaction.transactionId !== transactionId)
      );
      dispatch(updateUncontrolledTransactionsChanging({ type: 'REMOVE', rowNumber }));
    });
  };

  const addTbaUncontrolledTransaction = async (uncontrolledTransaction: UncontrolledTransaction, rowNumber: number) => {
    const parametersForThisTransaction =
      currentUncontrolledTransactionParameters?.filter(
        (parameterObject) =>
          parameterObject.uncontrolledTransaction.transactionId === uncontrolledTransaction.transactionId
      ) ?? [];

    const tbaUncontrolledParameters: TbaUncontrolledParametersPayload[] = [];
    const parametersAreUserEntered: boolean =
      tba.tbaEvaluationMethod.name !== 'CUT/CUP' || tba.transactionType.name !== 'Tangible Goods';

    if (parametersAreUserEntered) {
      const fields: FinancialInfoFieldValues[] =
        financialInfoFieldTypesMap[tba.tbaEvaluationMethod.name]![tba.transactionType.name] ?? [];
      fields.forEach((fieldName) => {
        const foundParameter = parametersForThisTransaction.find(
          (parameter) => parameter.tbaParameter?.type === fieldName
        );
        if (foundParameter) {
          tbaUncontrolledParameters.push(foundParameter);
        }
      });
    }

    const payload: {
      tbaId: number;
      container?: Container;
      transactionId: number;
      tbaUncontrolledParameters: TbaUncontrolledParametersPayload[];
    } = {
      tbaId: tba.tbaId,
      container,
      transactionId: uncontrolledTransaction.transactionId,
      tbaUncontrolledParameters
    };
    dispatch(updateUncontrolledTransactionsChanging({ type: 'ADD', rowNumber }));
    void dispatch(addTBAUncontrolledTransaction(payload)).then(async () => {
      await dispatch(fetchUncontrolledTransactionParameters(tba.tbaId));
      setTbalUncontrolledTransactions((prev) => [...prev, uncontrolledTransaction]);
      dispatch(updateUncontrolledTransactionsChanging({ type: 'REMOVE', rowNumber }));
    });
  };

  const handleSelectUncontrolledTransaction = (uncontrolledTransactionId: number) => {
    const currentIds = [...selectedIds];
    if (currentIds.includes(uncontrolledTransactionId))
      currentIds.splice(currentIds.indexOf(uncontrolledTransactionId), 1);
    else currentIds.push(uncontrolledTransactionId);

    setSelectedIds(currentIds);
  };

  const handleDeselectAll = () => {
    setSelectedIds([]);
    setAllUncontrolledSelected(false);
  };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  // selectAll function will be used when the api supports bulks operation
  const selectAll = () => {
    if (allUncontrolledSelected) {
      handleDeselectAll();
    } else {
      setSelectedIds(selectableAllUncontrolledTransactions.map(({ transactionId }) => transactionId));
    }
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const handleDeleting = async (confirmed: boolean) => {
    setIsDeleting(true);
    if (!confirmed || !deletingTx?.transactionId) {
      setIsDeleting(false);
      setShowDeleteModal(false);
      return;
    }

    const { transactionId } = deletingTx;
    const index = selectedIds.indexOf(transactionId);
    if (index !== -1) {
      selectedIds.splice(index, 1);
      setSelectedIds([...selectedIds]);
    }

    // TODO: add backend validation (this attended in another SC)
    await dispatch(deleteUncontrolledTransaction({ transactionId }));
    await refreshTBAandTrxs();
    setShowDeleteModal(false);
    setIsDeleting(false);
  };

  const onBulkDeleteFromAllUncontrolled = async (ids: number[]) => {
    await dispatch(bulkDeleteFromAllUncontrolled({ transactionIds: ids }));
    await refreshTBAandTrxs();
  };

  const onAddToSearch = async (ids: number[]) => {
    await dispatch(bulkAddToCurrentSearch({ tbaId: tba.tbaId, transactionIds: ids }));
    await refreshTBAandTrxs();
  };

  const refreshTBAandTrxs = async () => {
    await Promise.all([
      await dispatch(fetchCurrentTBA(tba.tbaId)),
      await dispatch(fetchUncontrolledTransactionParameters(tba.tbaId))
    ]);
  };

  const chipsData: SingleChipProps[] = [
    {
      isSelected: true,
      label: t('analysis:comparable-range-total'),
      amount: selectableAllUncontrolledTransactions.length,
      key: 'total'
    }
  ];
  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const stickyCols: StickyColsDefinition = {};
  let data: UncontrolledTransactionTableData[] = [];

  if (selectableAllUncontrolledTransactions) {
    const mapToTableData = selectableAllUncontrolledTransactions.map((uncontrolledTransaction, rowNumber) => {
      const items: IconMenuItem[] = [
        {
          text: t('analysis:tba-uncontrolled-transactions-list-menu-option-edit-transaction'),
          handleOnClick: () => {
            onEditSingleTransaction(uncontrolledTransaction.transactionId);
          }
        },
        {
          text: t('analysis:tba-uncontrolled-transactions-list-menu-option-delete-transaction'),
          handleOnClick: () => {
            setDeletingTx(uncontrolledTransaction);
            setShowDeleteModal(true);
          }
        }
      ];

      return {
        transactionId: currentSearchUncontrolledTransactionIds.includes(uncontrolledTransaction.transactionId) ? (
          <Box className={classes.selectedTransactionId}>{uncontrolledTransaction.identifier}</Box>
        ) : (
          <Box textAlign="center" style={{ ...body2 }}>
            {uncontrolledTransaction.identifier}
          </Box>
        ),
        entityName: `${
          uncontrolledTransaction.ucSourceLegalEntity?.name
            ? String(uncontrolledTransaction.ucSourceLegalEntity?.name)
            : String(uncontrolledTransaction.sourceLegalEntity?.name)
        } → ${
          uncontrolledTransaction.ucDestinationLegalEntity?.name
            ? String(uncontrolledTransaction.ucDestinationLegalEntity?.name)
            : String(uncontrolledTransaction.destinationLegalEntity?.name)
        }`,
        transactionType: uncontrolledTransaction?.transactionType.name,
        propertyTransferred: uncontrolledTransaction?.propertyTransferred,
        rangeValue:
          typeof uncontrolledTransaction.parameterValue === 'number' &&
          !Number.isNaN(uncontrolledTransaction.parameterValue)
            ? showRangeValueFixFormatting
              ? formatRangeValue(uncontrolledTransaction.parameterValue, isUnitPrice)
              : uncontrolledTransaction.parameterValue.toFixed(2)
            : 'N/A',
        className: classes.rowNotSelected,
        currentSearch: currentSearchUncontrolledTransactionIds.includes(uncontrolledTransaction?.transactionId) ? (
          <Box
            className={classes.removeAddTbaUncontrolledTransactionIcon}
            onClick={() => {
              void removeTbaUncontrolledTransactionFromCurrentSearch(uncontrolledTransaction.transactionId, rowNumber);
            }}
          >
            {transactionsChanging.includes(rowNumber) ? <RotatingSyncIcon /> : <RemoveCircleOutline />}
          </Box>
        ) : (
          <Box
            className={classes.removeAddTbaUncontrolledTransactionIcon}
            onClick={() => {
              void addTbaUncontrolledTransaction(uncontrolledTransaction, rowNumber);
            }}
          >
            {transactionsChanging.includes(rowNumber) ? <RotatingSyncIcon /> : <AddCircleOutline />}
          </Box>
        ),
        select: (
          <Checkbox
            checked={selectedIds.includes(Number(uncontrolledTransaction.transactionId))}
            value={uncontrolledTransaction.transactionId}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (uncontrolledTransaction.transactionId) {
                handleSelectUncontrolledTransaction(Number(event?.target?.value));
              }
            }}
          />
        ),
        actions: <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
      };
    });
    data = (sortData(
      filterData(mapToTableData, filterObject),
      sortObject
    ) as unknown) as UncontrolledTransactionTableData[];
  }

  const displayColumns = [
    {
      key: 'transactionId',
      header: (
        <SearchAndSort
          field={t('analysis:column-id')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'transactionId', sortOrder });
          }}
          onSearchChange={(value) => {
            setFilter({ ...filterObject, transactionId: value });
          }}
        />
      ),
      width: 100
    },
    {
      key: 'entityName',
      header: (
        <SearchAndSort
          field={t('analysis:column-entities')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'entityName', sortOrder });
          }}
          onSearchChange={(value) => {
            setFilter({ ...filterObject, entityName: value });
          }}
        />
      ),
      width: 100
    },
    { key: 'transactionType', header: t('analysis:column-transaction-type'), width: 100 },
    { key: 'propertyTransferred', header: t('transactions:label-property-transferred'), width: 100 },
    {
      key: 'rangeValue',
      header: showRangeValueFixFormatting
        ? getRangeValueHeader(transactionType, method, isUnitPrice, 'prefix')
        : t('analysis:column-source-range-value'),
      width: 100
    },
    { key: 'currentSearch', header: t('analysis:current-search'), width: 100 },
    /* This column is commented out because the API endpoint is not ready to suport bulk operations
    {
      key: 'select',
      header: (
        <Checkbox
          checked={selectedIds.length > 0 && selectedIds.length === selectableAllUncontrolledTransactions.length}
          onChange={selectAll}
        />
      ),
      width: 10
    }, 
    */
    { key: 'actions', header: '', width: 10 }
  ];

  return (
    <Paper className={classes.paperContainer}>
      <TbaUncontrolledTransactionsHeader
        title={t('analysis:all-uncontrolled')}
        chips={chipsData}
        tabLabel={tabLabel}
        selectedIds={selectedIds}
        handleDeselectAll={handleDeselectAll}
        setIsOpenImportFromRoyaltyStatModal={setIsOpenImportFromRoyaltyStatModal}
        onCreateSingleTransaction={onCreateSingleTransaction}
        onUploadMultipleTransactions={onUploadMultipleTransactions}
        onAddToSearch={onAddToSearch}
        onBulkDeleteFromAllUncontrolled={onBulkDeleteFromAllUncontrolled}
        onCopyTba={onCopyTba}
      />
      {savingNewTransaction ? (
        <CenteredProgress />
      ) : allUncontrolledTransactions.length > 0 ? (
        <Box className={classes.container}>
          <Box className={classes.tableWrapper}>
            <Table
              className={classes.scrollTable}
              data={data}
              columns={displayColumns}
              stickyCols={stickyCols}
              disabledRows={transactionsChanging}
            />
          </Box>
        </Box>
      ) : (
        <TBAAllUncontrolledNoTransSplashScreen />
      )}
      <ConfirmationModal
        open={showDeleteModal}
        title={t('analysis:tba-uncontrolled-transaction-delete-modal-title')}
        subtitle={`${t('analysis:tba-uncontrolled-transaction-delete-modal-subtitle')} ${
          deletingTx ? deletingTx.identifier : ''
        }`}
        text={t('analysis:tba-uncontrolled-transaction-delete-modal-text')}
        HeaderIcon={TrashCanIcon}
        handleClose={handleDeleting}
        isLoading={isDeleting}
      />
    </Paper>
  );
};
