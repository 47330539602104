import { ProductNames } from '@xbs/xbs-enums';
import decodeJWT from 'jwt-decode';
import { getAppConfig } from './configuration';
import httpService from '../services/http';
export interface JWTToken {
  email: string;
  userId: string;
  role: string;
}

export interface AuthStateInterface {
  authToken: string;
  expiration: number;
  refreshToken: string;
  lastRefreshDate: number;
}

export type LogoutReason =
  | 'no_container_found_on_account'
  | 'last_time_token_refresh_not_current_day'
  | 'refresh_token_response_not_ok'
  | 'authorize_request_failed'
  | 'append_xbs_headers_failed'
  | 'handling_error_response_network_error'
  | 'handling_error_response_401_status'
  | 'idle_timeout'
  | 'intended_logout'
  | 'session_expired'
  | 'eula_not_accepted';

export const redirectToLogin = async () => {
  const { loginStackRelativeUrl, exacteraDomainUrl, xbsEnvironmentShort } = getAppConfig();

  if (!loginStackRelativeUrl || !exacteraDomainUrl) {
    return;
  }

  if (isUserAuthenticated()) {
    try {
      await httpService.request<unknown>({
        method: 'get',
        apiUrlKey: 'authUrl',
        relativePath: `/v1/logout`
      });
    } catch {}
  }

  window.localStorage.clear();
  const currentEnv = window.location.hostname;

  const productUuid = ProductNames.ByName.Tpiq.Uuid;

  window.localStorage.setItem('productUuid', productUuid);
  const redirectUrl =
    xbsEnvironmentShort === 'prod'
      ? `https://tp.${exacteraDomainUrl}`
      : `https://tp.${xbsEnvironmentShort}.${exacteraDomainUrl}`;

  if (currentEnv === 'localhost' || currentEnv.includes('exactera')) {
    window.location.replace(`${window.location.origin}/${loginStackRelativeUrl}`);
  } else {
    window.location.replace(redirectUrl);
  }
};

export const logUserLogout = async (reason: LogoutReason) => {
  if (isUserAuthenticated()) {
    try {
      await httpService.request<unknown>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/event/user-logout`,
        data: { reason }
      });
    } catch {}
  }
};

export const isUserAuthenticated = () => {
  const auth = getAuthInfo();

  return (
    Boolean(auth.authToken) &&
    Boolean(auth.expiration) &&
    auth.expiration > Date.now() &&
    auth.lastRefreshDate === new Date().getDate()
  );
};

export const getAuthInfo = (): AuthStateInterface => ({
  authToken: localStorage.getItem('idToken') ?? '',
  refreshToken: localStorage.getItem('refreshToken') ?? '',
  expiration: localStorage.getItem('expires') ? Number(localStorage.getItem('expires')) * 1000 : Date.now() - 1000,
  lastRefreshDate: localStorage.getItem('lastRefreshDate')
    ? Number(localStorage.getItem('lastRefreshDate'))
    : new Date().getDate()
});

export const setAuthTokens = ({
  authToken = '',
  refreshToken = '',
  expiration
}: Partial<AuthStateInterface>): AuthStateInterface => {
  localStorage.setItem('idToken', String(authToken));
  localStorage.setItem('refreshToken', String(refreshToken));
  localStorage.setItem('expires', expiration ? `${expiration / 1000}` : '');
  localStorage.setItem('lastRefreshDate', String(new Date().getDate()));

  return getAuthInfo();
};

export const decodeTokens = (token: string) => {
  try {
    const jwt = decodeJWT(token);

    if (!jwt) {
      return null;
    }

    return {
      email: (jwt as JWTToken).email,
      userId: (jwt as JWTToken).userId,
      role: (jwt as JWTToken).role
    };
  } catch {
    return null;
  }
};
